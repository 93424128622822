import { UI_VERSION, DIRECT_DOMAIN, MSP_ENABLED} from './version';
import { CONTAINER, NGFW_SHARED, PRISMA_ACCESS_CONTAINER } from './service-lib/services/constants'

const manage_app = ({ sparkyFramework: { events, onAuthed, StartupManager, getFrameworkServiceUrl, fawkesUtils: { getRedirectURL, getAuthHeaders}, getAuthState, hasTsgSupport} }) => {
    onAuthed(({
        getFeatures,
        getLicenseInfo,
        getFqdnInfo,
        getAuthInfo,
        getSubTenantInfo,
        getMigrationStatus,
        getNotifications,
        setMainValues,
        getMainState,
        setValues,
        waitExtraPromises
    }) => {
        getFeatures();
        getLicenseInfo();
        getFqdnInfo();
        getAuthInfo();
        getMigrationStatus();
        getNotifications();
        const state = getMainState();
        const type = state.tenant.type
        const getWebSecurityPromise = () => {
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 10000)
			return fetch(getRedirectURL() + `/api/config/v9.2/enable`, {
                signal: controller.signal,
				withCredentials: true,
				method: 'GET',
				responseType: 'json',
				headers: getAuthHeaders(),
			})
				.then(async (response) => {
					let resp = await response.json();
					const isEnable = resp?.result?.enable;
					if (isEnable === 'yes') setValues({ webSecurityAvail: true });
                    clearTimeout(timeoutId);
				})
				.catch((err) => {
					console.log(err);
				});
		};

        const DASLicenseFetching = () => {
            const header = getAuthHeaders();
            const newHeader = {
                Authorization: `Bearer ${header['x-auth-jwt']}`
            }
            return fetch(`${getFrameworkServiceUrl('api_gateway')}/subscription/v1/refresh_tenant_device_licenses`, {
                withCredentials: true,
                method: 'GET',
                responseType: 'json',
                headers: newHeader,
            })
                .then(async (response) => {
                    let resp = await response.json();
                })
                .catch((err) => {
                    console.log(err);
                });
        };
        DASLicenseFetching()

        if (StartupManager.getConfigManagedType() === StartupManager.PANORAMA_CONFIG) {
            getSubTenantInfo()
        }
		if(type!==StartupManager.PANORAMA_CONFIG)
        waitExtraPromises([getWebSecurityPromise()]);
        //checking if sparky info is loaded and setting configLocation if not available
        //upon landing on SaaS security screen, configLocation is not yet set.
        // let intervalRuns = 0;
        // let interval = setInterval(()=>{
        //     const mainState = getMainState() || {};
        //     const {
        //         infoLoaded,
        //         configLocation = {},
        //         featuresInfo = [],
        //         tenant: {
        //             'ngfw_enabled': isNGFWEnabled = false
        //         } = {}
        //     } = mainState;

        //     if(infoLoaded && !configLocation?.container){
                
        //         const defaultContainer = !isNGFWEnabled ? {
        //             name: PRISMA_ACCESS_CONTAINER,
        //             type: 'container',
        //             locationPath: PRISMA_ACCESS_CONTAINER
        //         } : {
        //             name: NGFW_SHARED,
        //             type: 'container',
        //             locationPath: NGFW_SHARED
        //         };
        //         setMainValues({
        //             configLocation: { 
        //                 container: defaultContainer
        //             },
        //         });
                
        //         // clearInterval(interval)
        //     }else{
        //         intervalRuns++;
        //         if(intervalRuns > 10) clearInterval(interval);
        //     }
        // },500)
        
    })
     //local version set during deploy time
    let localVersion = UI_VERSION;
    let fn =  ({auth, onTsgUnload}) => {
        //Entering the TSG
        let adminURL = getAuthState()?.instances?.get('prisma_access')?.runtime_attributes?.admin_api_url;
        fetch(adminURL + `/api/activity/login`, {
            withCredentials: true,
            method: 'GET',
            responseType: 'json',
            headers: getAuthHeaders(),
        })
            .then(async (response) => {
                console.log('Entering the TSG ID: ', auth.tsg_id, 'TSG Name: ', auth.tsg_name);
            })
            .catch((err) => {
                console.log(err);
            });
        
        events?.once?.('logout', () => {
            return fetch(adminURL + `/api/activity/logout`, {
                withCredentials: true,
                method: 'GET',
                responseType: 'json',
                headers: getAuthHeaders(),
            })
                .then(async (response) => {
                    console.log('Leaving the TSG ID: ', auth.tsg_id, 'TSG Name: ', auth.tsg_name);
                })
                .catch((err) => {
                    console.log(err);
                });
        });

        return Promise.all([
            import(/* webpackPreload: true, webpackChunkName: "predef" */ './predefined'),
            import(/* webpackPreload: true */ './i18n'),
        ]).then(() => import(/* webpackPreload: true */ './routes')).then(r => r.default)
    }
    //Need to handle MSP case
    if (hasTsgSupport() && !DIRECT_DOMAIN && !MSP_ENABLED) {
        return (params) => {
            if (!getAuthState()?.instances?.isFawkesRunning) { // no running fawkes
                return null;
            }
            //detect the version
            let version = getAuthState()?.instances?.get('prisma_access')?.runtime_attributes?.version;
            console.log('version check - Sparky Version: ', version, ' - LocalVersion:', localVersion);
            if (localVersion === version) {
                console.log('Loading Version: ', localVersion);
                //varsion match. Load this site
                return fn(params);
            } else {
                //return null
                return null;
            }
        }
    } else {
        //Non TSG or MSP case
        console.log('Loading Cloud Management for NonTsg or Direct Domain or MSP app');
        return (params)=>fn(params);
    }
}
manage_app.exposes = {
    responsePage: () => import('./exposedFunctions.js'),
    DeviceManagementIntegration: () => import('./DeviceManagementIntegration')
}
export default manage_app;
